import { AUTH_UIDS_KEY } from "@/constants";

/**
 * @param {Object} user
 * @returns {Object} userinfo
 */
export const userSerializer = (user) => {
  const {
    first_name,
    last_name,
    middle_name,
    sex = "m",
    address,
    birthday,
    avatar,
    city,
    email,
    id,
    region_with_type,
  } = user;
  const full_name = [first_name, middle_name, last_name]
    .filter(Boolean)
    .join(" ");
  const short_name = [first_name, `${(last_name || "")[0]}.`]
    .filter(Boolean)
    .join(" ");
  return {
    first_name,
    last_name,
    middle_name,
    sex,
    address,
    birthday,
    full_name,
    short_name,
    avatar,
    city,
    email,
    talent_id: id,
    region_with_type,
  };
};

export const saveAuthId = (user_id) => {
  try {
    const stored = JSON.parse(localStorage.getItem(AUTH_UIDS_KEY)) || [];
    if (!stored.includes(user_id)) {
      stored.push(user_id);
      localStorage.setItem(AUTH_UIDS_KEY, JSON.stringify(stored));
    }
  } catch (error) {
    // ignore
  }
};

/**
 * Возвращает закодированную строку содержащую
 * список talent_id которые авторизовывались в НТО с этого клиента
 */
export const getSavedAuths = () => {
  try {
    const stored = JSON.parse(localStorage.getItem(AUTH_UIDS_KEY)) || [];
    if (stored.length > 1) {
      return btoa(stored.join());
    }
  } catch (error) {
    // ignore
  }
};
